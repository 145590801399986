import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import loading_img from "../../../images/profile/loading.gif";
import { GlobalSet } from '../global/GlobalProvider';
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
const Home = () => {
    document.title = "Affiliate Program - BetFanda";
    const { changeBackground } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "dark", label: "Dark" });
    }, []);

   const { BaseUrl } = GlobalSet();
   const { CurrencySymbol } = GlobalSet();
   const [homedata, setHomeData] = useState([]);  
   const [salider, setSalider] = useState([]);  
  /*.....product code........*/
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  const [loading, setLoading] = useState(true);
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       currency: selectedCurrency,
       }
  const APiResponse = () => {
      axios.post('/affiliate-index',data)
      .then(response=>{
         setSalider(response.data.slider)
         setHomeData(response.data.data_info)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);



  const Balance = BaseUrl + "public/appssource/all_icon/Balance.png";
  const Total_Partner = BaseUrl + "public/appssource/all_icon/Total_Partner.png";
  const ref_link = BaseUrl + "public/appssource/all_icon/ref_link.png";
  const today_earning = BaseUrl + "public/appssource/all_icon/today_earning.png";
  const total_profit = BaseUrl + "public/appssource/all_icon/total_profit.png";
  const Total_Income = BaseUrl + "public/appssource/all_icon/Total_Income.png";
  const total_withdrawal = BaseUrl + "public/appssource/all_icon/total_withdrawal.png";
    
//mobile odds option
  const casino_game_s = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                },
            },
        ],
  };




return(
     <>
      <span style={{  marginTop: '-36px' }} className="d-xl-block">

        <div className="row">
            <Fragment>

           <Col xl="12" className="p-0" >
              <Carousel className="p-0">
                {salider.map((carousel, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={BaseUrl+(carousel.img_link)}
                      className='d-block w-100'
                      alt={`Slide ${i + 1}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel> 



                <div className="col-xl-12  mt-4">
                  <div className="row mydivpadding">
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <img
                              src={Balance}
                              alt=""
                              width="30px"
                              className="me-3 card-list-img"
                            />
                            <div className="ms-4">
                              <h3 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(homedata.balance).toFixed(4))}</h3>
                              <p className="mb-0 text-nowrap">Balance</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <img
                              src={Total_Partner}
                              alt=""
                              width="30px"
                              className="me-3 card-list-img"
                            />
                            <div className="ms-4">
                              <h3 className="mb-0 font-w600">{ homedata.count_aff_u } P</h3>
                              <p className="mb-0">Total Partner</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <img
                              src={ref_link}
                              alt=""
                              width="30px"
                              className="me-3 card-list-img"
                            />
                            <div className="ms-4">
                              <h3 className="mb-0 font-w600">0</h3>
                              <p className="mb-0">Total App Link</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <img
                              src={today_earning}
                              alt=""
                              width="30px"
                              className="me-3 card-list-img"
                            />
                            <div className="ms-4">
                              <h3 className="mb-0 font-w600">$0</h3>
                              <p className="mb-0">Today Earning</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <img
                              src={total_profit}
                              alt=""
                              width="30px"
                              className="me-3 card-list-img"
                            />
                            <div className="ms-4">
                              <h3 className="mb-0 font-w600">$0</h3>
                              <p className="mb-0">Total Profit</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <img
                              src={Total_Income}
                              alt=""
                              width="30px"
                              className="me-3 card-list-img"
                            />
                            <div className="ms-4">
                              <h3 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(homedata.total_Income).toFixed(4))}</h3>
                              <p className="mb-0">Total Income</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <img
                              src={total_withdrawal}
                              alt=""
                              width="30px"
                              className="me-3 card-list-img"
                            />
                            <div className="ms-4">
                              <h3 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(homedata.total_withdraw).toFixed(4))}</h3>
                              <p className="mb-0">Total Withdrawal</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

             </Col>

          </Fragment>
        </div> 
      </span>
    </>
  )
}
export default Home;